import React, { useState } from 'react';

import { Container, Navbar, Button, Row, Col, Image, Form, FloatingLabel } from 'react-bootstrap'
import { FaHeart, FaLongArrowAltRight } from "react-icons/fa";
import ReactGA from 'react-ga4';

import ovalImg from "./assets/img/oval.png"
import crossImg from "./assets/img/cross.png"
import educationImg from "./assets/img/education.png"

function App() {
  // React-Bootstrap Handle Submit form
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const clickHandlerContactSubmitButton = () => {
    ReactGA.event({
      category: "Button",
      action: "Clicked submit contactusButton button"
    });
  }

  const clickHandlerSurveyButton = () => {
    ReactGA.event({
      category: "Button",
      action: "Clicked survey surveyButton button"
    });
  }

  const clickHandlerLogoButton = () => {
    ReactGA.event({
      category: "Button",
      action: "Clicked logo logoButton button"
    });
  }


  return (
    <>
      <Navbar className='bg-warning'>
        <Container>
          <Navbar.Brand href="/" className='p-3'>
            <img
              src={crossImg}
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt='Orthodox'
              id='logoButton'
              onClick={clickHandlerLogoButton}
            />
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container>
        <Row className='align-items-center pt-3 pb-8'>
          <Col md={6} className='text-md-start text-center py-6'>
            <h1 className="mb-4 fs-9 fw-bold">Have you been looking for an Eastern Orthodox Christian school?</h1>
            <p className="lead text-secondary">
              You've come to the right place!
            </p>
            <p className="lead text-secondary">
              We are a group of concerned <b>Orthodox Christians</b> from various parishes and jurisdictions in <b>Central Massachusetts</b> looking to make a change for our children.
            </p>
            <p className="lead text-secondary">
              Our committee members include seasoned <b>business executives, young professionals, concerned parents and Orthodox clergy</b>.
            </p>
            <p className="lead text-secondary">
              We are inspired by organizations such as the <b>Chrysostom Academy in Pennsylvania</b> and others that created amazing schools based on classical education and Orthodoxy.
            </p>
            <p className="mb-6 lead text-secondary">
              Please help us in this endeavor by filling out our survey.
            </p>
            <div className="text-center text-md-start">
              <Button variant="warning" size="lg" href="https://forms.gle/Qh8mEcsQfDyEX3rk6" className='me-3' target='_blank' id='surveyButton' onClick={clickHandlerSurveyButton}>
                Click here for our survey! <FaLongArrowAltRight />
              </Button>
            </div>
          </Col>
          <Col md={6} className='text-end'>
            <Image className='pt-7 pt-md-0 img-fluid' src={educationImg} alt=""></Image>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <div className='py-md-11 py-8'>
          <div className="bg-holder z-index--1 bottom-0 background-position-top opacity-50">
            <Image src={ovalImg} alt="" className='w-100'></Image>
          </div>
          <Row className='justify-content-center'>
            <Col lg={6} className='text-center'>
              <h1 className="fw-bold mb-5 fs-1">Questions/Comments</h1>
              <Container>
                <Row>
                  <Form
                    name="contact-v1"
                    method="POST"
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}>

                    <Form.Control type="hidden" name="form-name" value="contact-v1" />

                    <Form.Group className="mb-3" controlId="contact-v1.NameInput">
                      <FloatingLabel controlId="floatingInput" label="Your Name" className="mb-3">
                        <Form.Control
                          required
                          name="name"
                          type="text"
                          placeholder="Your Name" />
                        <Form.Control.Feedback type="invalid" className='text-start'>
                          Please enter your name.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="contact-v1.EmailInput">
                      <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
                        <Form.Control
                          required
                          name="email"
                          type="email"
                          placeholder="Email address" />
                        <Form.Control.Feedback type="invalid" className='text-start'>
                          Please enter valid email address like (name@example.com).
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="contact-v1.MessageInput">
                      <FloatingLabel controlId="floatingTextarea" label="Message">
                        <Form.Control
                          required
                          name="message"
                          as="textarea"
                          placeholder="Message"
                          style={{ height: '150px' }} />
                        <Form.Control.Feedback type="invalid" className='text-start'>
                          Please enter a message.
                        </Form.Control.Feedback>
                      </FloatingLabel>
                    </Form.Group>

                    <Row className='justify-content-center'>
                      <Button className='bg-warning border-warning text-dark w-50' type="submit" id="contactusButton" onClick={clickHandlerContactSubmitButton}>
                        Contact Us
                      </Button>
                    </Row>
                  </Form>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </Container>

      <Container>
        <div className='text-center pb-5'>
          <Container className='border-top py-3'>
            <Row className='justify-content-between'>
              <Col md='auto' className='mb-1 mb-md-0'>
                <p className="mb-0">&copy; 2022 The Orthodox School </p>
              </Col>
              <Col md='auto'>
                <p className="mb-0">Made with<span className="mx-1 text-danger"><FaHeart /></span>by Local Orthodox Parishioners</p>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default App;
